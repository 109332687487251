<template>
  <div style="display: flex">
    <navigation index="settings">
      <div style="width: 100%; padding: 5px">
        <el-tabs
          v-model="activeName"
          class="demo-tabs"
          @tab-click="handleClick"
        >
          <el-tab-pane
            :label="tools.GetLanguageValue('web.店铺信息')"
            name="store"
          >
            <el-scrollbar :height="ContentHeight + 'px'">
              <fieldset>
                <legend>{{ tools.GetLanguageValue("web.店铺信息") }}</legend>
                <el-form>
                  <el-form-item label="Logo" label-width="150">
                    <el-upload
                      class="avatar-uploader"
                      :action="UploadPictureImageUrl"
                      :show-file-list="false"
                      :on-success="UploadProductPicterSuccess"
                      :before-upload="UploadProductPicterSuccess"
                      :headers="headersImage"
                    >
                      <img
                        v-if="storeSettings.LogoFormat"
                        :src="storeSettings.LogoFormat"
                        class="avatar"
                      />
                      <el-icon v-else class="avatar-uploader-icon"
                        ><Plus
                      /></el-icon>
                    </el-upload>
                  </el-form-item>

                  <el-form-item
                    :label="tools.GetLanguageValue('web.店铺名称')"
                    label-width="150"
                  >
                    <el-input
                      :placeholder="tools.GetLanguageValue('web.名称')"
                      v-model="storeSettings.Name"
                      disabled="false"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    :label="tools.GetLanguageValue('web.地址')"
                    label-width="150"
                  >
                    <el-input
                      :placeholder="tools.GetLanguageValue('web.地址')"
                      v-model="storeSettings.Location"
                      disabled="false"
                    ></el-input>
                  </el-form-item>

                  <el-form-item label="NIE/CIF" label-width="150">
                    <el-input
                      placeholder="NIE/CIF"
                      v-model="storeSettings.TaxNumber"
                      disabled="false"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    :label="tools.GetLanguageValue('web.邮箱')"
                    label-width="150"
                  >
                    <el-input
                      placeholder="Email"
                      v-model="storeSettings.Mailbox"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    :label="tools.GetLanguageValue('system.public.手机号')"
                    label-width="150"
                  >
                    <el-input
                      placeholder="PhoneNumber"
                      v-model="storeSettings.PhoneNumber"
                    ></el-input>
                  </el-form-item>

                  <el-form-item
                    :label="tools.GetLanguageValue('web.店铺介绍')"
                    label-width="150"
                  >
                    <QuillEditor
                      style="width: 100%; height: 200px"
                      v-model:content="storeSettings.Content"
                      :content-type="'html'"
                      :toolbar="toolbarOptions"
                    ></QuillEditor>
                  </el-form-item>

                  <el-form-item label-width="150">
                    <el-button @click="updateOtherSetting" type="primary"
                      >更新</el-button
                    >
                  </el-form-item>
                </el-form>
              </fieldset>
            </el-scrollbar>
          </el-tab-pane>
          <el-tab-pane
            :label="tools.GetLanguageValue('web.基本设置')"
            name="jibenshezhi"
          >
            <el-form-item
              label-width="230"
              :label="tools.GetLanguageValue('web.库存')"
            >
              <el-checkbox border v-model="storeSettings.AllowNegativeStocks">
                {{ tools.GetLanguageValue("web.允许负库存") }}</el-checkbox
              >
            </el-form-item>
            <el-form-item
              label-width="230"
              :label="tools.GetLanguageValue('web.默认临时商品税率')"
            >
              <el-input-number
                v-model="storeSettings.DefaultTaxRate"
              ></el-input-number>
            </el-form-item>

            <!-- <el-form-item
              label-width="230"
              :label="tools.GetLanguageValue('web.默认服务税率')"
            >
              <el-input-number
                v-model="storeSettings.DefaultServiceTaxRate"
              ></el-input-number>
            </el-form-item> -->

            <el-form-item
              label-width="230"
              :label="tools.GetLanguageValue('web.维修税率')"
            >
              <el-input-number
                v-model="storeSettings.MaintainTaxRate"
              ></el-input-number>
            </el-form-item>

            <el-form-item
              label-width="230"
              :label="tools.GetLanguageValue('web.临时维修税率')"
            >
              <el-input-number
                v-model="storeSettings.TempMaintainTaxRate"
              ></el-input-number>
            </el-form-item>
            
<!-- 
            <el-form-item
              label-width="230"
              :label="tools.GetLanguageValue('web.临时服务/配件税率')"
            >
              <el-input-number
                v-model="storeSettings.TempServiceTaxRate"
              ></el-input-number>
            </el-form-item> -->

            <el-form-item
              label-width="230"
              :label="tools.GetLanguageValue('web.是否打印税率')"
            >
              <el-switch v-model="storeSettings.PrintMaintainTaxRate" />
            </el-form-item>

            <el-form-item
              label-width="230"
              :label="tools.GetLanguageValue('web.币种符号')"
            >
              <el-input
                v-model="storeSettings.Currency"
              ></el-input>
            </el-form-item>

            <el-form-item label-width="150">
              <el-button @click="updateOtherSetting" type="primary"
                >更新</el-button
              >
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane
            :label="tools.GetLanguageValue('web.打印设置')"
            name="print"
          >
            <el-divider content-position="left">{{
              tools.GetLanguageValue("web.打印设置")
            }}</el-divider>

            <el-form ref="form" label-width="180px">
              <el-form-item>
                <el-checkbox v-model="printAndTermData.DotPrintDrawer" border>
                  {{ tools.GetLanguageValue("web.不打印操作人") }}
                </el-checkbox>
              </el-form-item>

              <el-form-item>
                <el-checkbox
                  border
                  v-model="printAndTermData.DotPrintCounterfoil"
                >
                  {{ tools.GetLanguageValue("web.不打印存根") }}
                </el-checkbox>
              </el-form-item>

              <el-form-item :label="tools.GetLanguageValue('web.打印语言')">
                <el-select
                  v-model="printAndTermData.PrintLanguageId"
                  placeholder="请选择"
                  @change="ChangePrintLanguage"
                  style="width: 160px"
                >
                  <el-option
                    v-for="item in Languages"
                    :key="item.Id"
                    :label="item.Name"
                    :value="item.Id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button @click="updatePrintSetting" type="primary">{{
                  tools.GetLanguageValue("web.更新")
                }}</el-button>
              </el-form-item>
            </el-form>

            <el-divider content-position="left">{{
              tools.GetLanguageValue("web.打印设置")
            }}</el-divider>
            <el-form ref="form" label-width="180px">
              <el-row>
                <el-col :span="6">
                  <el-form-item :label="tools.GetLanguageValue('web.打印地址')">
                    <el-input
                      :placeholder="tools.GetLanguageValue('web.打印地址')"
                      v-model="PrintSettings.PrintUrl"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <div style="margin-left: 15px">
                    ({{ tools.GetLanguageValue("web.本地打印使用") }}:<span
                      style="color: red"
                      >localhost</span
                    >)
                  </div>
                </el-col>
                <el-col :span="12">
                  <el-form-item :label="tools.GetLanguageValue('web.打印端口')">
                    <el-input-number
                      v-model="PrintSettings.PrintPort"
                    ></el-input-number>
                  </el-form-item>
                </el-col>

                <el-col :span="24">
                  <el-form-item>
                    <el-button @click="UpdatePrintInfo" type="primary">{{
                      tools.GetLanguageValue("web.保存")
                    }}</el-button>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-tab-pane>

          <el-tab-pane :label="tools.GetLanguageValue('web.条款')" name="term">
            <el-scrollbar :height="ContentHeight - 60 + 'px'">
              <div>
                {{ tools.GetLanguageValue("web.预定条款") }} :
                <QuillEditor
                  v-model:content="printAndTermData.ReserveTerm"
                  :content-type="'html'"
                  :toolbar="editToolbar"
                  theme="snow"
                ></QuillEditor>

                <br />
                {{ tools.GetLanguageValue("web.维修条款") }}:
                <QuillEditor
                  v-model:content="printAndTermData.ConditionsOfRepair"
                  :content-type="'html'"
                  :toolbar="editToolbar"
                  theme="snow"
                ></QuillEditor>

                <br />
                {{ tools.GetLanguageValue("web.保修条款") }}:
                <QuillEditor
                  v-model:content="printAndTermData.WarrantyTerms"
                  :content-type="'html'"
                  :toolbar="editToolbar"
                  theme="snow"
                ></QuillEditor>

                <br />
                {{ tools.GetLanguageValue("web.备注") }}:
                <QuillEditor
                  v-model:content="printAndTermData.Remark"
                  :content-type="'html'"
                  :toolbar="editToolbar"
                  theme="snow"
                ></QuillEditor>
                <br />

                {{ tools.GetLanguageValue("web.销售小票备注") }}:

                <el-input
                  v-model="printAndTermData.MarketReceiptRemark"
                  type="textarea"
                />
                <br />

                {{ tools.GetLanguageValue("web.销售A4小票备注") }}:

                <el-input
                  v-model="printAndTermData.MarketA4Remark"
                  type="textarea"
                />
                <br />
              </div>
            </el-scrollbar>

            <div style="height: 60px; display: flex; align-items: center">
              <el-button @click="updatePrintSetting" type="primary">{{
                tools.GetLanguageValue("web.更新")
              }}</el-button>
            </div>
          </el-tab-pane>

          <el-tab-pane
            :label="
              item.LanguageName + '-' + tools.GetLanguageValue('web.条款')
            "
            :name="item.LanguageName"
            v-for="item in printAndTermData.Languages"
            :key="item.Id"
          >
            <el-scrollbar :height="ContentHeight - 60 + 'px'">
              <div>
                {{ tools.GetLanguageValue("web.预定条款") }} :
                <QuillEditor
                  style="height: 200px"
                  v-model:content="item.Language.ReserveTerm"
                  :content-type="'html'"
                  :toolbar="editToolbar"
                  theme="snow"
                ></QuillEditor>

                <br />
                {{ tools.GetLanguageValue("web.维修条款") }}:
                <QuillEditor
                  v-model:content="item.Language.ConditionsOfRepair"
                  :content-type="'html'"
                  :toolbar="editToolbar"
                  theme="snow"
                ></QuillEditor>

                <br />
                {{ tools.GetLanguageValue("web.保修条款") }}:
                <QuillEditor
                  v-model:content="item.Language.WarrantyTerms"
                  :content-type="'html'"
                  :toolbar="editToolbar"
                  theme="snow"
                ></QuillEditor>

                <br />
                {{ tools.GetLanguageValue("web.备注") }}:
                <QuillEditor
                  v-model:content="item.Language.Remark"
                  :content-type="'html'"
                  :toolbar="editToolbar"
                  theme="snow"
                ></QuillEditor>
                <br />

                {{ tools.GetLanguageValue("web.销售小票备注") }}:

                <el-input
                  v-model="item.Language.MarketReceiptRemark"
                  type="textarea"
                />
                <br />

                {{ tools.GetLanguageValue("web.销售A4小票备注") }}:

                <el-input
                  v-model="item.Language.MarketA4Remark"
                  type="textarea"
                />
                <br />
              </div>
            </el-scrollbar>
            <div style="height: 60px; display: flex; align-items: center">
              <el-button @click="updatePrintSetting" type="primary">{{
                tools.GetLanguageValue("web.更新")
              }}</el-button>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </navigation>
  </div>
</template>
<script setup>
import { ref, onMounted, onBeforeMount } from "vue";
import axios from "../../../commons/AxiosMethod.js";
import tools from "../../../commons/tools.js";
import navigation from "../../../components/Modules/Navigation.vue";
import { ElMessageBox, ElMessage } from "element-plus";
import { QuillEditor } from "@vueup/vue-quill";
import { Plus } from "@element-plus/icons-vue";

import "@vueup/vue-quill/dist/vue-quill.snow.css";

const ContentHeight = ref(document.documentElement.clientHeight - 80);

const editToolbar = ref([["bold", "italic", "underline", "strike"]]);
const activeName = ref("store");
const selectLanguage = ref([]);
const printLanguage = ref([]);
const customLanguage = ref([]);

const printAndTermData = ref({
  Id: undefined,
  DotPrintDrawer: false,
  ReserveTerm: "",
  ConditionsOfRepair: "",
  WarrantyTerms: "",
  Remark: "",
}); // 打印和条款数据
const languageData = ref({
  Id: undefined,
  SelectLanguage: 0,
  PrintLanguage: 0,
  CustomLanguage: [],
}); // 语言数据
const otherData = ref({
  Id: undefined,
  Name: "",
  Address: "",
  Email: "",
  NieOrCifNumber: "",
  TelephoneNo: "",
  Web: "",
}); // 其它数据 店铺信息

const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // 加粗，斜体，下划线，删除线

  ["blockquote", "code-block"], //引用，代码块

  [{ header: 1 }, { header: 2 }], // 几级标题

  [{ list: "ordered" }, { list: "bullet" }], // 有序列表，无序列表

  [{ script: "sub" }, { script: "super" }], // 下角标，上角标

  [{ indent: "-1" }, { indent: "+1" }], // 缩进

  [{ direction: "rtl" }], // 文字输入方向

  [{ size: ["small", false, "large", "huge"] }], // 字体大小

  [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题

  [{ color: [] }, { background: [] }], // 颜色选择

  [
    {
      font: [
        "SimSun",
        "SimHei",
        "Microsoft-YaHei",
        "KaiTi",
        "FangSong",
        "Arial",
      ],
    },
  ], // 字体

  [{ align: [] }], // 居中

  ["clean"], // 清除样式,

  ["link", "image"], // 上传图片、上传视频
];

const handleClick = (tab, event) => {
  activeName.value = tab.props.name;
  // console.log(tab, event)
  // console.log(activeName.value)
};
// 页面加载前
onBeforeMount(() => {
  getLanguageEnum();
});

const PrintSettings = ref({
  PrintPort: 10086,
  PrintUrl: null,
}); //打印设置

const UploadPictureImageUrl = ref("");
const UploadFullImageFileUrl = ref("");
const headersImage = ref({});

// 页面加载
onMounted(() => {
  // console.log(activeName.value)
  GetStoreSettings();

  loadPrintSetting();
  GetLanguageList();

  UploadPictureImageUrl.value = axios.GetUrl() + "/api/Upload/ImageFileWebp";
  UploadFullImageFileUrl.value =
    axios.GetUrl() + "/api/Upload/FullImageFileWebp";

  headersImage.value = {
    Authorization: localStorage.getItem("Authentication_Header"),
  };

  PrintLanguageId.value = localStorage.getItem("PrintLanguageId");

  try {
    PrintSettings.value = JSON.parse(localStorage.getItem("PrintSettings"));
    if (PrintSettings.value == null) {
      PrintSettings.value = ref({
        PrintPort: 10086,
        PrintUrl: "localhost",
      });
    }
  } catch (e) {
    PrintSettings.value = ref({
      PrintPort: 10086,
      PrintUrl: "localhost",
    });
  }
});

const ComImageUrl = ref("");
const ComImageFormet = ref("");

//上传列表图
const UploadProductPicterSuccess = (response, file, fileList) => {
  if (response.Code == 200) {
    storeSettings.value.LogoFormat = response.Data.ImageFormet;
    storeSettings.value.Logo = response.Data.FileName;
  }
};

//修改打印设置
const UpdatePrintInfo = () => {
  if (!(PrintSettings.value.PrintPort > 0)) {
    ElMessageBox({
      title: "提示",
      message: tools.GetLanguageValue("web.请输入端口"),
      type: "error",
    });
    return;
  }
  if (
    PrintSettings.value.PrintUrl == "" ||
    PrintSettings.value.PrintUrl == null ||
    PrintSettings.value.PrintUrl == undefined
  ) {
    ElMessageBox({
      title: "提示",
      message: tools.GetLanguageValue("web.请输入地址"),
      type: "error",
    });
    return;
  }

  localStorage.setItem("PrintSettings", JSON.stringify(PrintSettings.value));
};

//保存设置
const ChangePrintLanguage = (item) => {
  localStorage.setItem("PrintLanguageId", item);
  PrintLanguageId.value = item;
};

const Languages = ref({});
//语言的列表
const GetLanguageList = (item) => {
  const param = {};
  axios.apiMethod("/all/Language/GetLanguageList", "get", param, (result) => {
    Languages.value = result.Data;
  });
};

const SettingWindow = ref(false);
const PrintLanguageId = ref("");

const storeSettings = ref({
  PrintTaxRate: 0,
  TempServiceTaxRate: 0,
  DefaultAttachmentTaxRate: 0,
  DefaultServiceTaxRate: 0,
  Currency:""
});
// 获取店铺信息
const GetStoreSettings = () => {
  axios.apiMethod("/stores/Setting/GetStoreSettings", "get", {}, (result) => {
    storeSettings.value = result.Data;
  });
};

// 更改其它设置
const updateOtherSetting = () => {
  // 改变之后调用更新方法
  axios.apiMethod(
    "/stores/Setting/StoreUpdateSettings",
    "post",
    storeSettings.value,
    (result) => {
      if (result.Data.IsSuccess) {
        ElMessage({
          title: "提示",
          type: "success",
          message: "操作成功",
        });
      } else {
        ElMessageBox({
          message: result.Data.Msg,
          type: "error",
        });
      }
    }
  );
};

// 获取打印设置
const loadPrintSetting = () => {
  axios.apiMethod("/stores/Setting/GetPrintSetting", "get", {}, (result) => {
    printAndTermData.value = result.Data;
  });
};
// 更新打印设置
const updatePrintSetting = () => {
  // 改变之后调用更新方法
  axios.apiMethod(
    "/stores/Setting/AddOrEditPrintSetting",
    "post",
    printAndTermData.value,
    (result) => {
      if (result.Data.IsSuccess) {
        loadPrintSetting();
        ElMessage({
          title: "提示",
          type: "success",
          message: "操作成功",
        });
      } else {
        ElMessageBox({
          message: result.FullMsg,
          type: "error",
        });
      }
    }
  );
};

// 获取语言枚举
const getLanguageEnum = () => {
  axios.apiMethod("/sys/SystemData/GetLanguageEnum", "get", null, (result) => {
    // console.log(result.Data)
    selectLanguage.value = result.Data;
    printLanguage.value = result.Data;
    customLanguage.value = result.Data;
  });
};

// 自定义语言
const checkLanguageChange = (obj) => {
  // console.log(obj)
  // console.log(languageData.value)
  updateLanguageSetting();
};
// 选择语言
const selectLanguageChange = (obj) => {
  updateLanguageSetting();
};
// 选择打印语言
const selectPrintLanguageChange = (obj) => {
  updateLanguageSetting();
};
// 获取语言设置
const loadLanguageSetting = () => {
  axios.apiMethod("/stores/Setting/GetLanguageSetting", "get", {}, (result) => {
    if (result.Code === 200) {
      languageData.value = result.Data;
    }
  });
};
// 更新语言设置
const updateLanguageSetting = () => {
  if (languageData.value.Id === undefined) {
    return;
  }
  // 改变之后调用更新方法
  axios.apiMethod(
    "/stores/Setting/AddOrEditLanguageSetting",
    "post",
    languageData.value,
    (result) => {
      if (result.Code === 200) {
        languageData.value = result.Data;
        // ElMessageBox({
        //   title: "提示",
        //   message: "操作成功"
        // })
      } else {
        ElMessageBox({
          message: result.FullMsg,
          type: "error",
        });
      }
    }
  );
};
</script>
<style>
fieldset {
  border: 2px solid #e9e9e9;
  border-radius: 5px;
  padding: 16px;
}
fieldset legend {
  padding: 0 8px !important;
  font-size: 16px !important;
  width: auto !important;
  border: none !important;
  margin-bottom: 0 !important;
}

.ql-editor {
  height: 200px; /* 你想要的高度 */
}
</style>
